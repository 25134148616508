var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title":
            this.type === "view"
              ? "法人组织管理查看"
              : this.type === "add"
              ? "法人组织管理新增"
              : "法人组织管理编辑",
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave()
          },
          "head-save-cancel": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("form-layout", {
        ref: "formLayout",
        attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }